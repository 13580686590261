@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");

html {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  font-optical-sizing: auto;
  font-style: normal;
}
